import React, { useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "./Whoo";
import EChartsWrapper from "../EChartsWrapper";
import { useSelector } from "react-redux";
import { Loader } from "../UI/Loader/Loader";

function China() {
  const { influenzaData } = useSelector((state) => state.dashboard);
  const memoizedInfluenzaData = useMemo(() => influenzaData, [influenzaData]);

  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);

  const renderReportDummy = () => {
    return (
      <div>
        {/* Report Summary Heading */}
        <h6 className="mt-4 mb-3 report-header">
          Report on Influenza-like Illness (ILI) Cases
        </h6>

        {/* Countries Reporting */}
        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            In Week 17 of 2024 (April 22 - April 28, 2024), the percentage of
            ILI cases reported by sentinel hospitals in southern provinces was
            4.7%, which was higher than the previous week's level (4.6%) and
            higher than the same period in 2021-2022 (3.7% and 3.1%), but lower
            than the same period in 2023 (5.2%).
          </span>
        </div>

        {/* Total Positive Specimens */}
        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            In the northern provinces, the ILI percentage reported by sentinel
            hospitals was 3.6%, higher than the previous week’s level (3.5%),
            and also higher than the same period in 2021-2023 (2.4%, 1.7%, and
            3.2%).
          </span>
        </div>

        <h6 className="mt-4 mb-3 report-header">Pathogenic Surveillance</h6>
        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            In Week 17 of 2024, the national influenza surveillance network
            laboratories tested 10,047 samples from ILI cases. Southern
            provinces detected 496 influenza virus-positive samples, of which
            358 were A(H1N1)pdm09, 41 were A(H3N2), and 97 were B(Victoria). In
            northern provinces, 207 influenza virus- positive samples were
            detected, including 129 of A(H1N1)pdm09, 22 of A(H3N2), and 53 of
            B(Victoria).
          </span>
        </div>

        <h6 className="mt-4 mb-3 report-header">Outbreaks</h6>
        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            In Week 17 of 2024, a total of 8 outbreaks of ILI cases were
            reported nationwide. Of these, 4 were due to A(H1N1)pdm09, 1 was due
            to A(H3N2), 1 was influenza-negative, and 2 had no pathogen test
            results available.
          </span>
        </div>

        <h6 className="mt-4 mb-3 report-header">
          Influenza Surveillance in Other Countries
        </h6>
        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            Globally, during Week 15 (April 8-14, 2024), flu activity continued
            to decrease in most Northern Hemisphere countries. There were
            ongoing outbreaks in some Central American, Caribbean, and Eastern
            European countries. In the Southern Hemisphere, influenza activity
            remained low, with some countries in South America reporting an
            increase in A-type influenza.
          </span>
        </div>
      </div>
    );
  };
  return (
    <>
      {loading && <Loader />}
      {!loading && (
        <ErrorBoundary>
          <h2>China</h2>
          <div className="card-body d-flex flex-row justify-content-between">
            {/* Left Side - Report */}
            <div className="content" style={{ width: "55%" }}>
              {renderReportDummy()}
            </div>

            {/* Right Side - Charts */}
            <div className="image-container">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/4/44/Graf.png"
                alt="Graph Chart"
                className="img-fluid"
              />
            </div>
          </div>
        </ErrorBoundary>
      )}
    </>
  );
}

export default China;
