// dashboard.slice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const fetchData = createAsyncThunk(
  "dashboard/fetchData",
  async (_, { rejectWithValue }) => {
    try {
      const response = await Promise.all([
        fetch(
          `${process.env.REACT_APP_BACKEND_URL}/dashboard/dashboard/influenza_specimens`
        ),
        fetch(
          `${process.env.REACT_APP_BACKEND_URL}/dashboard/dashboard/ili_cases`
        ),
        fetch(
          `${process.env.REACT_APP_BACKEND_URL}/dashboard/dashboard/sari_cases`
        ),
        fetch(
          `${process.env.REACT_APP_BACKEND_URL}/dashboard/who-latest-summary`
        ),
      ]);

      const data = await Promise.all(response.map((res) => res.json()));

      // Preprocess Influenza Data
      const influenzaData = data[0].map((item) => ({
        date: item.date,
        count:
          item.AH1N12009 +
          item.AH3 +
          item.AH5 +
          item.ANOTSUBTYPED +
          item.BNOTDETERMINED +
          item.BVICTORIA +
          item.BYAMAGATA,
      }));

      // Preprocess ILI Data
      const iliData = data[1].map((item) => ({
        date: item.date,
        count: item.ILI_ARI_cases,
      }));

      // Preprocess SARI Data
      const sariData = data[2].map((item) => ({
        date: item.date,
        count: item.number_of_cases,
      }));

      return [influenzaData, iliData, sariData, data[3]];
    } catch (err) {
      return rejectWithValue(err.message);
    }
  }
);

const initialState = {
  influenzaData: [],
  iliData: [],
  sariData: [],
  report: null,
  loading: false,
  error: null,
  dataLoaded: false,
};

const dashboardSlice = createSlice({
    name: "dashboard",
    initialState,
    reducers: {
      refreshData(state) {
        state.loading = true;
        state.dataLoaded = false;
      },
    },
    extraReducers: (builder) => {
      builder
        .addCase(fetchData.pending, (state) => {
          state.loading = true;
        })
        .addCase(fetchData.fulfilled, (state, action) => {
          [state.influenzaData, state.iliData, state.sariData, state.report] =
            action.payload;
          state.loading = false;
          state.dataLoaded = true;
        })
        .addCase(fetchData.rejected, (state, action) => {
          state.error = action.payload;
          state.loading = false;
          state.dataLoaded = true;
        });
    },
  });

export const { refreshData } = dashboardSlice.actions;

export default dashboardSlice.reducer;
