// src/redux/store.js
import { configureStore } from '@reduxjs/toolkit';
import dashboardReducer from './slices/dashboard.slice';
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // Defaults to localStorage for web

// Persist configuration
const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['dashboard'], // Specify which slices you want to persist
};

// Wrap the dashboard reducer with persistReducer
const persistedReducer = persistReducer(persistConfig, dashboardReducer);

// Configure the store
export const store = configureStore({
  reducer: {
    dashboard: persistedReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore redux-persist actions
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

// Create a persistor
export const persistor = persistStore(store);

export default store;
