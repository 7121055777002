// src/components/EChartsWrapper.jsx
import React from 'react';
import ReactECharts from 'echarts-for-react';
import PropTypes from 'prop-types';

const EChartsWrapper = ({ title, data, color }) => {
  const option = {
    title: {
      text: title,
      left: 'center',
      textStyle: {
        fontSize: 16,
      },
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      },
      formatter: function (params) {
        const date = params[0].axisValue;
        const count = params[0].data;
        return `${date}<br/>Count: ${count}`;
      },
    },
    legend: {
      data: ['Count'],
      top: 30,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true,
    },
    toolbox: {
      feature: {
        saveAsImage: {},
      },
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: data.map(item => item.date),
      axisLabel: {
        rotate: 45,
        formatter: function (value) {
          // Format the date for better readability
          const date = new Date(value);
          // Check if date is valid
          if (!isNaN(date)) {
            return `${date.getMonth() + 1}/${date.getDate()}/${date.getFullYear()}`;
          } else {
            return value; // Fallback to original value
          }
        },
      },
    },
    yAxis: {
      type: 'value',
      name: 'Count',
    },
    series: [
      {
        name: 'Count',
        type: 'line',
        data: data.map(item => item.count),
        smooth: true,
        lineStyle: {
          color: color,
        },
        itemStyle: {
          color: color,
        },
        areaStyle: {
          color: `${color}80`, // Adding transparency
        },
      },
    ],
    dataZoom: [
      {
        type: 'slider',
        start: 0,
        end: 100,
      },
      {
        type: 'inside',
      },
    ],
  };

  return (
    <div className="chart-item mb-4">
      <ReactECharts option={option} style={{ height: '300px', width: '100%' }} />
    </div>
  );
};

EChartsWrapper.propTypes = {
  title: PropTypes.string.isRequired,
  data: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.string.isRequired,
      count: PropTypes.number.isRequired,
    })
  ).isRequired,
  color: PropTypes.string.isRequired,
};

export default EChartsWrapper;
