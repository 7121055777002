export const IconButton = ({ Icon, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="d-flex btn btn-primary rounded-circle"
      style={{
        width: "40px",
        height: "40px",
        alignItems: "center",
        justifyContent: "center",
        background: "#3187ae"
      }}
    >
      <Icon />
    </button>
  );
};
