import React, { useState, useEffect, useRef } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

function Mane_header() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [isDropdownOpen, setDropdownOpen] = useState(false);
  const [userInfo, setUserInfo] = useState({
    fullName: '',
    email: '',
    jobTitle: '',
  });
  const navigate = useNavigate();
  const dropdownRef = useRef(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user && user.status === 'logged_in') {
      setIsLoggedIn(true);
      setUserInfo({
        fullName: user.full_name || 'N/A',
        email: user.email || 'N/A',
        jobTitle: user.job_title || 'User',
      });
    } else {
      setIsLoggedIn(false);
    }
  }, []);

  const toggleDropdown = () => {
    setDropdownOpen(!isDropdownOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const Logout = async () => {
    try {
      console.log('Initiating logout process...');
      console.clear();
      const user = JSON.parse(localStorage.getItem('user') || '{}');
      const isMicrosoftUser = user.microsoft_id != null;
      const response = await fetch(`${process.env.REACT_APP_BACKEND_URL}/auth/${isMicrosoftUser ? 'microsoft-logout' : 'logout-user'}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ token: user.token }),
      });
      if (!response.ok) {
        throw new Error('Logout failed');
      }
      clearLocalStorage();
      setIsLoggedIn(false);
      navigate('/');
    } catch (error) {
      console.error('Logout error:', error);
    }
  };

  const clearLocalStorage = () => {
    localStorage.removeItem('user');
  };

  
  return (
    <header className="bg-white">
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center py-2 me-2">
          <div className="">
            <h3 className="mt-3 ms-3 mb-3">
              <Link to="/who" className="text-decoration-none">
                <span style={{ color: '#aeba00' }}>AI-FLU</span>
                <span style={{ color: '#367693' }}> MONITOR</span>
              </Link>
            </h3>
          </div>
          {isLoggedIn ? (
            <div className="position-relative" ref={dropdownRef}>
              <div className="dropdown">
                <div
                  className="d-flex align-items-center"
                  onClick={toggleDropdown}
                  style={{ cursor: 'pointer' }}
                  id="dropdownMenuLink"
                  aria-expanded={isDropdownOpen}
                >
                  <img 
                    src="assets/images/profile_image.png" 
                    alt="Profile" 
                    className="rounded-circle me-2" 
                    style={{width: '40px', height: '40px', objectFit: 'cover'}} 
                  />
                  <div className='d-none d-sm-block'>
                    <p className="mb-0">{userInfo.fullName}</p>
                    <small className="text-muted">{userInfo.jobTitle}</small>
                  </div>
                  <i className={`fa fa-chevron-${isDropdownOpen ? 'down' : 'up'} ms-2`} aria-hidden="true"></i>
                </div>
                <div className={`dropdown-menu${isDropdownOpen ? ' show' : ''}`} aria-labelledby="dropdownMenuLink">
                  <Link className="dropdown-item" to="/profile">Profile</Link>
                  <button className="dropdown-item" onClick={Logout}>Log Out</button>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-auto">
              <Link to="/login" className="btn me-2  btn-success">Sign In</Link>
            </div>
          )}
        </div>
      </div>
    </header>
  );
}

export default Mane_header;