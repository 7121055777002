import React from 'react';
import { useLocation, Link } from 'react-router-dom';

function Header_menu() {
  const location = useLocation();
  const currentPath = location.pathname;

  const navLinks = [
    { path: "/who", label: "Who" },
    { path: "/russia", label: "Russia" },
    { path: "/italy", label: "Italy" },
    { path: "/spain", label: "Spain" },
    { path: "/china", label: "China" },
  ];

  return (
    <>
      <nav>
        <div className="card-header" style={{ backgroundColor: "#3187ae" }}>
          <ul className="nav nav-tabs card-header-tabs">
            {navLinks.map(link => (
              <li className="nav-item" key={link.path}>
                <Link
                  className={currentPath === link.path ? "nav-link active" : "nav-link"}
                  to={link.path}
                  style={currentPath === link.path ? { color: "#3187ae" } : { color: "white" }}
                >
                  {link.label}
                </Link>
              </li>
            ))}
          </ul>
        </div>
      </nav>
    </>
  );
}

export default Header_menu;