import React, { useState, useEffect, useMemo } from "react";
import { ErrorBoundary } from "./Whoo";
import EChartsWrapper from "../EChartsWrapper";
import { useSelector } from "react-redux";
import { Loader } from "../UI/Loader/Loader";

function Italy() {
  const { influenzaData } = useSelector((state) => state.dashboard);
  const memoizedInfluenzaData = useMemo(() => influenzaData, [influenzaData]);
  const [report, setReport] = useState(() => {
    // Retrieve data from local storage on initial load
    const storedReport = localStorage.getItem("italyReport");
    return storedReport ? JSON.parse(storedReport) : null;
  });
  console.log("report_italy: ", report);
  // const [loading, setLoading] = useState(!report);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!report || report.detail === "list index out of range") {
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_BACKEND_URL}/dashboard/italy-latest-summary`
      )
        .then((response) => response.json())
        .then((data) => {
          if (data.error) {
            setError(data.error);
          } else {
            setReport(data);
            localStorage.setItem("italyReport", JSON.stringify(data));
          }
          setLoading(false);
        })
        .catch((err) => {
          setError("Failed to fetch Italy report");
          setLoading(false);
        });
    }
  }, [report]);

  // Memoizing the report to avoid unnecessary calculations or renders
  const memoizedReport = useMemo(() => report, [report]);

  const renderListItems = (data) => {
    if (!data) return null;
    return data
      .split("\n")
      .filter((item) => item.trim() !== "")
      .map((item, index) => {
        const cleanedItem = item
          .replace(/^[*-]\s*/, "")
          .replace(/^\*\*.*?:\*\*/, "")
          .trim();
        if (cleanedItem) {
          return (
            <li key={index} className="list-group-item border-0">
              <i
                className="fa fa-check-circle"
                style={{ color: "#b0bc04" }}
              ></i>{" "}
              {cleanedItem}
            </li>
          );
        }
        return null;
      })
      .filter(Boolean);
  };

  const renderReportDummy = () => {
    // if (!report) return <div>No report data available</div>;

    return (
      <div>
        {/* Report Summary Heading */}
        <h6 className="mt-4 mb-3 report-header">Influnet Report</h6>

        {/* Countries Reporting */}
        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            With the seventeenth week, the RespiVirNet surveillance comes to an
            end. In this final week, the incidence of influenza-like illness
            (ILI) in Italy has reached the baseline threshold with a level of
            3.8 cases per 1,000 patients (4.5 in the previous week).
          </span>
        </div>

        {/* Total Positive Specimens */}
        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            Among children under the age of five, the incidence is 11.7 cases
            per 1,000 patients (13.4 in the previous week).
          </span>
        </div>

        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            In seven regions, the incidence level is still above the baseline
            threshold: Lombardy, Autonomous Province of Bolzano, Autonomous
            Province of Trento, Marche, Abruzzo, Campania, and Sardinia.
          </span>
        </div>

        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            Valle d'Aosta and Calabria have not activated the RespiVirNet
            surveillance.
          </span>
        </div>

        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            A special thanks to all the doctors and pediatricians, regional and
            corporate representatives, regional reference laboratories, and the
            Ministry of Health for their valuable contribution and continuous
            commitment.
          </span>
        </div>

        {/* Influenza Subtypes Reported */}

        <div className="report-item">
          <span>
            During Week 17/2024, the percentage of samples testing positive for
            influenza out of the total analyzed was 2.1%. Since the start of the
            season, influenza A viruses have been largely predominant (91.3%)
            compared totype B viruses, with the A(H1N1)pdm09 subtype being the
            most prevalent. All B viruses characterized so far have belonged to
            the Victoria lineage. Among the samples analyzed in Week 17/2024, 9
            (0.9%) tested positive for SARS-CoV-2, 5 (0.5%) for RSV, while 278
            tested positive for other respiratory viruses, including: 121
            Rhinoviruses, 63 Metapneumoviruses, 58 Parainfluenza viruses, 19
            Adenoviruses, 13 human Coronaviruses (excluding SARS-CoV-2), and 4
            Bocaviruses. The co-circulation of various respiratory viruses
            contributes to the recorded ILI incidence value in Week 17 (3.8
            cases per 1,000 patients, as reported in the RespiVirNet
            Epidemiological Report of 05/03/2024), particularly Rhinoviruses,
            Metapneumoviruses, and Parainfluenza viruses. This latest report
            includes an update on the results of phylogenetic analyses conducted
            on the hemagglutinin (HA) genes of the influenza A(H1N1)pdm09,
            A(H3N2), and B viruses circulating in Italy during the 2023/2024
            season.
          </span>
        </div>

        <h6 className="mt-4 mb-3 report-section">
          RespiVirNet Epidemiological Report
        </h6>

        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            This report outlines the national and regional results of the
            RespiVirNet epidemiological surveillance of influenza-like illnesses
            (ILI) in Italy, conducted by the Infectious Diseases Department of
            the Istituto Superiore di Sanità.
          </span>
        </div>

        {/* Total Positive Specimens */}
        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            In Week 17 of 2024, the surveillance concludes, with the ILI
            incidence reaching the baseline level of 3.8 cases per 1,000
            patients (compared to 4.5 in the previous week).
          </span>
        </div>

        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            Among children under five, the incidence is 11.7 cases per 1,000
            patients (previously 13.4).
          </span>
        </div>

        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            Seven regions still have ILI incidence levels above the baseline:
            Lombardy, Bolzano, Trento, Marche, Abruzzo, Campania, and Sardinia.
          </span>
        </div>

        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            Valle d'Aosta and Calabria did not activate the RespiVirNet
            surveillance.
          </span>
        </div>

        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            The percentage of influenza-positive samples in Week 17 is 2.1%.
            Throughout the season, influenza A viruses have predominated
            (91.3%), with the A(H1N1)pdm09 subtype being the most common.
          </span>
        </div>

        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            Additionally, some cases tested positive for other respiratory
            viruses, such as Rhinovirus, Metapneumovirus, and Parainfluenza
            viruses.
          </span>
        </div>
      </div>
    );
  };

  if (loading) return <Loader />;
  if (error) return <p>Error: {error}</p>;
  if (!memoizedReport) return <p>No report available for Italy</p>;

  return (
    <>
      <h2>Italy</h2>
      {/* <div className="card-body d-flex">
        <div className="content">
          {memoizedReport.heading && <h5 className="card-title">{memoizedReport.heading}</h5>}
          {memoizedReport.data1 && (
            <>
              <h6>Incidence of Influenza-like Illness (ILI):</h6>
              <ul className="list-group list-group-flush mb-3">
                {renderListItems(memoizedReport.data1)}
              </ul>
            </>
          )}
          {memoizedReport.data2 && (
            <>
              <h6>Age Group Analysis:</h6>
              <ul className="list-group list-group-flush mb-3">
                {renderListItems(memoizedReport.data2)}
              </ul>
            </>
          )}
          {memoizedReport.data3 && (
            <>
              <h6>Additional Information:</h6>
              <ul className="list-group list-group-flush mb-3">
                {renderListItems(memoizedReport.data3)}
              </ul>
            </>
          )}
        </div>
        <div className="image-container">
          <img src="https://upload.wikimedia.org/wikipedia/commons/4/44/Graf.png" alt="Graph Chart" className="img-fluid" />
        </div>
      </div> */}
      <ErrorBoundary>
        <div className="card-body d-flex flex-row justify-content-between">
          {/* Left Side - Report */}
          <div className="content" style={{ width: "55%" }}>
            {/* <h4>WHO</h4>
            <h5> */}
              {/* Report Year {new Date().getFullYear()}, Week {getCurrentWeekNumber()} */}
              {/* Published 25 September 2024 | For reporting Week 37, ending 15
              September 2024
            </h5> */}
            {renderReportDummy()}
          </div>

          {/* Right Side - Charts */}
          <div className="image-container">
            <img
              src="https://upload.wikimedia.org/wikipedia/commons/4/44/Graf.png"
              alt="Graph Chart"
              className="img-fluid"
            />
          </div>
        </div>
      </ErrorBoundary>
    </>
  );
}

export default Italy;
