// src/components/pages/Whoo.jsx
import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import "./styles/Whoo.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchData, refreshData } from "../../redux/slices/dashboard.slice";
import { getCurrentWeekNumber } from "../../utils/cfunctions";
import EChartsWrapper from "../EChartsWrapper";

import { LuRefreshCcw } from "react-icons/lu";
import { IconButton } from "../UI/IconButton";
import { Loader } from "../UI/Loader/Loader";

export class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true, error };
  }

  componentDidCatch(error, info) {
    console.error("Error Boundary Caught an error:", error, info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="error">
          Something went wrong: {this.state.error.message}
        </div>
      );
    }

    return this.props.children;
  }
}

ErrorBoundary.propTypes = {
  children: PropTypes.node.isRequired,
};

function Whoo() {
  const dispatch = useDispatch();
  const {
    influenzaData,
    iliData,
    sariData,
    report,
    loading,
    error,
    dataLoaded,
    lastFetched,
  } = useSelector((state) => state.dashboard);
  console.log("whoSummary: ", report);

  const memoizedInfluenzaData = useMemo(() => influenzaData, [influenzaData]);
  const memoizedIliData = useMemo(() => iliData, [iliData]);
  const memoizedSariData = useMemo(() => sariData, [sariData]);

  useEffect(() => {
    const FRESHNESS_THRESHOLD = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
    const now = Date.now();

    if (
      !dataLoaded ||
      !lastFetched ||
      now - lastFetched > FRESHNESS_THRESHOLD
    ) {
      dispatch(fetchData());
    }
    // Else, use persisted data
  }, [dispatch, dataLoaded, lastFetched]);

  const handleRefresh = () => {
    dispatch(refreshData());
    dispatch(fetchData());
  };

  const renderReport = () => {
    if (!report) return <div>No report data available</div>;

    return (
      <div>
        {/* Report Summary Heading */}
        <h6 className="mt-4 mb-3 report-header">{report.report_summary}</h6>

        {/* Countries Reporting */}
        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>{report.countries_reporting}</span>
        </div>

        {/* Total Positive Specimens */}
        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>{report.total_positive_specimens}</span>
        </div>

        {/* Influenza Subtypes Reported */}
        <h6 className="mt-4 mb-3 report-section">
          Influenza Subtypes Reported
        </h6>
        <ul>
          {Object.entries(report.subtypes_reported).map(([subtype, count]) => (
            <li key={subtype}>
              <i className="fa fa-check-circle icon"></i>
              <span>
                {subtype}: {count} {count === 1 ? "country" : "countries"}
              </span>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const renderReportDummy = () => {
    if (!report) return <div>No report data available</div>;

    return (
      <div>
        {/* Report Summary Heading */}
        <h6 className="mt-4 mb-3 report-header">{report.report_summary}</h6>

        {/* Countries Reporting */}
        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            In the Northern hemisphere, influenza activity in temperate
            countries remained at interepidemic levels. Activity was elevated in
            Western Africa (due to A(H3N2) and B viruses), Middle Africa (due to
            A(H3N2) viruses), Western Asia (due to A(H1N1)pdm09 and B viruses),
            Southern Asia (due to A(H1N1)pdm09 viruses), South East Asia (due to
            A(H1N1)pdm09 viruses) and Central America and the Caribbean (due to
            A(H3N2) viruses). Activity continued to increase in a few countries
            in Central America, Western and Middle Africa, and Southern Asia.
          </span>
        </div>

        {/* Total Positive Specimens */}
        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            In the Southern hemisphere, influenza activity remained elevated in
            some countries in South America (due to B viruses), Eastern Africa
            (due to A and B viruses), and Oceania (due to A viruses). Activity
            declined or was similar compared with the prior report across the
            Southern hemisphere.
          </span>
        </div>

        {/* Influenza Subtypes Reported */}
        <h6 className="mt-4 mb-3 report-section">SARS-CoV-2</h6>
        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            SARS-CoV-2 activity remained elevated in many countries in Europe
            and a few countries in Central America and the Caribbean, Western
            Asia, and Eastern Asia. Activity decreased or was similar compared
            with the prior report in Europe and increased in a few reporting
            countries in Central America and the Caribbean and Western Africa.
          </span>
        </div>
      </div>
    );
  };

  if (loading && !dataLoaded) return <Loader />;
  if (error) return <div className="error">Error: {error}</div>;

  return (
    <ErrorBoundary>
      <div className="d-flex justify-content-between">
        <h2>WHO</h2>
        <IconButton Icon={LuRefreshCcw} onClick={handleRefresh} />
      </div>
      <div className="card-body d-flex flex-row justify-content-between">
        {/* Left Side - Report */}
        <div className="content" style={{ width: "55%" }}>
          <h5>
            {/* Report Year {new Date().getFullYear()}, Week {getCurrentWeekNumber()} */}
            Published 25 September 2024 | For reporting Week 37, ending 15
            September 2024
          </h5>
          {renderReportDummy()}
        </div>

        {/* Right Side - Charts */}
        <div
          className="charts-container"
          style={{ width: "45%", display: "flex", flexDirection: "column" }}
        >
          <EChartsWrapper
            title="Influenza Specimens"
            data={memoizedInfluenzaData}
            color="#8884d8"
          />
          <EChartsWrapper
            title="ILI Cases"
            data={memoizedIliData}
            color="#82ca9d"
          />
          <EChartsWrapper
            title="SARI Cases"
            data={memoizedSariData}
            color="#ffc658"
          />
        </div>
      </div>
    </ErrorBoundary>
  );
}

export default Whoo;
