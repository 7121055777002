import React, { useState, useEffect } from 'react';
import Whoo from './componant/pages/Whoo';
import Footer from './componant/Footer';
import Spain from './componant/pages/Spain';
import Russia from './componant/pages/Russia';
import Italy from './componant/pages/Italy';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';
import Register from './componant/register_login/Register';
import Login from './componant/register_login/Login';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap-icons/font/bootstrap-icons.json';
import Resetpassword from './componant/register_login/Resetpassword';
import Header_menu from './componant/Header_menu';
import Chatwithdb from './componant/chatandreport/Chatwithdb';
import Mane_header from './componant/Mane_header';
import China from './componant/pages/China';
import Profile from './componant/Profile';
import Newpassword from './componant/register_login/Newpassword';
import Error from './componant/Error';
import PrivateRoute from './componant/PrivateRoute';
import html2pdf from 'html2pdf.js';
import { createRoot } from 'react-dom/client';

function App() {

  const location = useLocation();
  const [showManeHeader, setShowManeHeader] = useState(false);
  const [showHeaderMenu, setShowHeaderMenu] = useState(false);
  const [showButtons, setShowButtons] = useState(false);
  const [backButton, setBackButton] = useState(false);
  const [showFooter, setShowFooter] = useState(false);

  const user = JSON.parse(localStorage.getItem('user'));
  const isLoggedIn = user?.status === 'logged_in';

  useEffect(() => {
    const path = location.pathname;
    if (path === '/chatwithdb' || path === '/profile') {
      setShowManeHeader(true);
      setShowHeaderMenu(false);
      setShowButtons(false);
      setBackButton(true);
      setShowFooter(true);
    } else if (path === '/who' || path === '/spain' || path === '/italy' || path === '/russia' || path === '/china') {
      setShowManeHeader(true);
      setShowHeaderMenu(true);
      setShowButtons(true);
      setBackButton(false);
      setShowFooter(true);
    } else if (path === '/register' || path === '/login' || path === '/resertpassword') {
      setShowManeHeader(false);
      setShowHeaderMenu(false);
      setShowButtons(false);
      setBackButton(false);
      setShowFooter(false);
    } else {
      setShowManeHeader(false);
      setShowHeaderMenu(false);
      setShowButtons(false);
      setBackButton(false);
      setShowFooter(false);
    }
  }, [location]);

  const generateComprehensivePDFDummy = () => {
    const pdfPath = "/pdf/influenza_global_report.pdf";  
    const link = document.createElement('a');
    link.href = pdfPath;
    link.download = 'global_influenza_report.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const generateComprehensivePDF = async () => {
    const dashboards = [
      { component: Whoo, title: 'WHO Influenza Report'},
      { component: Russia, title: 'Russia Influenza Report'},
      { component: Italy, title: 'Italy Influenza Report'},
      { component: Spain, title: 'Spain Influenza Report'},
      { component: China, title: 'China Influenza Report'}
    ];
  
    const pdfContent = document.createElement('div');
    pdfContent.style.width = '210mm';
    pdfContent.style.margin = '0';
    pdfContent.style.padding = '0';
  
    for (let i = 0; i < dashboards.length; i++) {
      const dashboard = dashboards[i];
      const dashboardContent = await renderDashboardOffscreen(dashboard.component);
      
      const dashboardWrapper = document.createElement('div');
      dashboardWrapper.style.pageBreakAfter = 'always';
      dashboardWrapper.style.padding = '20px';
      dashboardWrapper.style.boxSizing = 'border-box';
  
      const title = document.createElement('h1');
      title.textContent = dashboard.title;
      title.style.marginBottom = '20px';
  
      dashboardWrapper.appendChild(title);
      dashboardWrapper.appendChild(dashboardContent);
      pdfContent.appendChild(dashboardWrapper);
    }
  
    const opt = {
      margin: 0,
      filename: 'Influenza_Global_report.pdf',
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: { scale: 2, useCORS: true },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'portrait' },
      pagebreak: { mode: ['css', 'legacy'] }
    };
  
    try {
      await html2pdf().from(pdfContent).set(opt).save();
      console.log('PDF generated successfully');
    } catch (error) {
      console.error('Error generating PDF:', error);
    }
  };
  
  const renderDashboardOffscreen = (DashboardComponent) => {
    return new Promise((resolve) => {
      const offscreenContainer = document.createElement('div');
      offscreenContainer.style.position = 'absolute';
      offscreenContainer.style.left = '-9999px';
      offscreenContainer.style.width = '210mm';
      document.body.appendChild(offscreenContainer);
  
      const root = createRoot(offscreenContainer);
      root.render(<DashboardComponent />);
  
      setTimeout(() => {
        const content = offscreenContainer.querySelector('.card-body');
        if (content) {
          content.style.pageBreakAfter = 'auto';
          content.style.pageBreakBefore = 'auto';
          content.style.width = '100%';
          content.style.maxWidth = '210mm';
          content.style.margin = '0';
          content.style.padding = '0';
          content.style.boxSizing = 'border-box';
        }
        const clonedContent = content ? content.cloneNode(true) : document.createElement('div');
        root.unmount();
        document.body.removeChild(offscreenContainer);
        resolve(clonedContent);
      }, 1000);
    });
  };
  
  return (
    <>
      {showManeHeader && <Mane_header />}
      <div className={isLoggedIn ? 'pt-5' : ''}>
        <div className={isLoggedIn ? 'container container-custom': ''} id="invoice">
        {backButton && <Link to="/who" className="btn p-0 mb-4 d-flex align-items-center"><i class="fa fa-chevron-left" aria-hidden="true"></i><span className='ms-2 fw-medium'>Back</span></Link>}
              <div className="card border-0">
                {showHeaderMenu && <Header_menu />}
                <div className={isLoggedIn ? 'tab-content p-4' : ''}>
                  <Routes>
                    <Route path="/who" element={<PrivateRoute Component={Whoo} /> } />
                    <Route path="/spain" element={<PrivateRoute Component={Spain} />} />
                    <Route path="/italy" element={<PrivateRoute Component={Italy}/>} />
                    <Route path="/russia" element={<PrivateRoute Component={Russia}/>} />
                    <Route path="/china" element={ <PrivateRoute Component={China} /> } />
                    <Route path="/chatwithdb" element={<PrivateRoute Component={Chatwithdb} /> } />
                    <Route path="/profile" element={ <PrivateRoute Component={Profile} /> } />

                    <Route path="/register" element={ <Register />} />
                    <Route path="/" element={ <Login/> } />
                    <Route path="/resertpassword" element={ <Resetpassword />} />
                    <Route path="/newpassword" element={<Newpassword />} />
                    <Route path="/error" element={<Error />} />
                  </Routes>
                </div>
              </div>
              {showButtons && (
            <div className="mt-3 d-flex justify-content-end gap-2">
            <button onClick={generateComprehensivePDFDummy} className="btn btn-outline-success" style={{ borderColor: '#3f3d5b' }}>
              Create Global Report
            </button>
            <Link to="/chatwithdb" className="btn btn-outline-primary">
              Chat with the Database
            </Link>
          </div>
              )}
        </div>
      </div>
      {showFooter && <Footer />}
    </>
  );
}

export default App;