import React, { useState, useEffect, useMemo } from "react";
import { ErrorBoundary } from "./Whoo";
import EChartsWrapper from "../EChartsWrapper";
import { useSelector } from "react-redux";
import { Loader } from "../UI/Loader/Loader";

function Russia() {
  const { influenzaData } = useSelector((state) => state.dashboard);
  const memoizedInfluenzaData = useMemo(() => influenzaData, [influenzaData]);
  const [report, setReport] = useState(() => {
    // Retrieve data from local storage on initial load
    const storedReport = localStorage.getItem("russiaReport");
    return storedReport ? JSON.parse(storedReport) : null;
  });
  console.log("report_russia: ", report);
  // const [loading, setLoading] = useState(!report);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  }, []);
  const [error, setError] = useState(null);

  // Fetch data if report is not available
  useEffect(() => {
    if (!report || report.detail === "list index out of range") {
      setLoading(true);
      fetch(
        `${process.env.REACT_APP_BACKEND_URL}/dashboard/russia-latest-summary`
      )
        .then((response) => response.json())
        .then((data) => {
          setReport(data);
          localStorage.setItem("russiaReport", JSON.stringify(data)); // Store data in local storage
          setLoading(false);
        })
        .catch((err) => {
          setError(err.message);
          setLoading(false);
        });
    }
  }, [report]);

  // Memoizing the report to avoid unnecessary calculations or renders
  const memoizedReport = useMemo(() => report, [report]);

  if (loading) return <Loader />;
  if (error) return <p>Error: {error}</p>;
  if (!memoizedReport) return <p>No report available.</p>;

  const renderReportDummy = () => {
    // if (!report) return <div>No report data available</div>;

    return (
      <div>
        {/* Report Summary Heading */}
        <h4>NATIONAL WEEKLY INFLUENZA BULLETIN OF THE RUSSIAN FEDERATION</h4>
        <h6 className="mt-4 mb-3 report-header">Influnet Report</h6>

        <div className="report-item">
          <span>
            With the seventeenth week, the RespiVirNet surveillance comes to an
            end. In this final week, the incidence of influenza-like illness
            (ILI) in Italy has reached the baseline threshold with a level of
            3.8 cases per 1,000 patients (4.5 in the previous week).
          </span>
        </div>
        {/* Countries Reporting */}

        <h6 className="mt-4 mb-3 report-header">Key findings include:</h6>

        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            Incidence: The morbidity rate was 40.6 per 10,000 population, lower
            than the baseline of 70.0. Hospitalization rates stayed consistent
            with the previous week.
          </span>
        </div>

        {/* Total Positive Specimens */}
        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            Among children under the age of five, the incidence is 11.7 cases
            per 1,000 patients (13.4 in the previous week).
          </span>
        </div>

        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            Influenza Strains Detected: Among 7,101 samples, only 1% tested
            positive for influenza. Influenza B was more prevalent, with 66
            cases reported in 17 cities. Four cases of unsubtyped influenza A
            were detected in 3 cities.
          </span>
        </div>

        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            Antigenic Characterization: The majority of influenza A(H3N2)
            strains were similar to the Northern Hemisphere's 2023-2024 vaccine
            strain (A/Darwin/09/2021). Some drift variants of A(H3N2) were
            detected. Influenza B viruses resembled the A/Austria/1359417/2021
            vaccine strain.
          </span>
        </div>

        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            A special thanks to all the doctors and pediatricians, regional and
            corporate representatives, regional reference laboratories, and the
            Ministry of Health for their valuable contribution and continuous
            commitment.
          </span>
        </div>

        {/* Influenza Subtypes Reported */}

        <div className="report-item">
          <i className="fa fa-check-circle icon"></i>
          <span>
            ARVI and COVID-19: 17% of respiratory samples were positive for
            other ARVIs. Common viruses detected were rhinovirus (9%),
            parainfluenza (2.5%), and metapneumovirus (0.7%). COVID-19 accounted
            for 4.2% of positive cases, with 11,550 new cases and 8 deaths
            reported for the week.
          </span>
        </div>

        <div className="report-item">
          <span>
            Overall, influenza B appears to dominate current viral activity,
            while the overall level of influenza and ARI is still below epidemic
            thresholds.
          </span>
        </div>
      </div>
    );
  };

  return (
    <>
      <h2>Russia</h2>
      <div className="card-body d-flex">
        {/* <div className="content">
          {memoizedReport.heading && <h5 className="card-title">{memoizedReport.heading}</h5>}

          {memoizedReport.data1 && <h6>{memoizedReport.data1}</h6>}
          {memoizedReport.data2 && (
            <ul className="list-group list-group-flush mb-3">
              {memoizedReport.data2.split('\n').map((item, index) => (
                <li key={index} className="list-group-item border-0">
                  <i className="fa fa-check-circle" style={{ color: "#b0bc04" }}></i> {item}
                </li>
              ))}
            </ul>
          )}

          {memoizedReport.data3 && <h6>Additional Details:</h6>}
          {memoizedReport.data3 && (
            <ul className="list-group list-group-flush mb-3">
              {memoizedReport.data3.split('\n').slice(1).map((item, index) => (
                <li key={index} className="list-group-item border-0">
                  <i className="fa fa-check-circle" style={{ color: "#b0bc04" }}></i> {item}
                </li>
              ))}
            </ul>
          )}
        </div> */}
        {/* Graph Chart Image */}
        {/* <div className="image-container">
          <img src="https://upload.wikimedia.org/wikipedia/commons/4/44/Graf.png" alt="Graph Chart" className="img-fluid" />
        </div> */}
        <ErrorBoundary>
          <div className="card-body d-flex flex-row justify-content-between">
            {/* Left Side - Report */}
            <div className="content" style={{ width: "55%" }}>
              {/* <h4>WHO</h4> */}
              {/* <h5> */}
              {/* Report Year {new Date().getFullYear()}, Week {getCurrentWeekNumber()} */}
              {/* Published 25 September 2024 | For reporting Week 37, ending 15
                September 2024
              </h5> */}
              {renderReportDummy()}
            </div>

            {/* Right Side - Charts */}
            <div className="image-container">
              <img
                src="https://upload.wikimedia.org/wikipedia/commons/4/44/Graf.png"
                alt="Graph Chart"
                className="img-fluid"
              />
            </div>
          </div>
        </ErrorBoundary>
      </div>
    </>
  );
}

export default Russia;
