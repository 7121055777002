// import { useState } from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ Component }) => {
 
// const [isAuthenticated, setIsAuthenticated] = useState(false);
const user = JSON.parse(localStorage.getItem('user'));
const isLoggedIn = user?.status === 'logged_in';
 // Your authentication logic goes here...
 
  return isLoggedIn ? <Component /> : <Navigate to="/" />;
};
export default PrivateRoute;



// import { Navigate } from "react-router-dom";

// const PrivateRoute = ({ Component }) => {
//   // const user = JSON.parse(localStorage.getItem('user'));
//   // const isLoggedIn = user?.status === 'logged_in';

//   // Temporary bypass: Hardcoding the isLoggedIn to true
//   const isLoggedIn = true;

//   return isLoggedIn ? <Component /> : <Navigate to="/" />;
// };

// export default PrivateRoute;